import React, { Component } from "react";
import "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class SearchDeliveryNotesBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search,
      searchBV: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search !== this.props.search) {
      this.setState({});
    }
  }

  handleSearch = (e) => {
    if (e.target.name === "searchBV") {
      this.setState({
        searchBV: !this.state.searchBV,
      });
    } else {
      this.setState({
        search: e.target.value,
      });
    }
    this.props.handleSearch(e, this.state.searchBV);
  };
  search = () => {
    this.props.searchFunc(this.state.search, this.state.searchBV);
  };
  render() {
    return (
      <React.Fragment>
        <div className="col-6 col-md-3 offset-md-4">
          <input
            className="search"
            type="text"
            name="search"
            placeholder="Suchen"
            onChange={this.handleSearch}
            onKeyPress={this.props.onKeyPress(this.state.searchBV)}
            value={this.state.search}
          />
        </div>
        <div className="col-2 col-md-1">
          <button onClick={this.search} className="btn-search yellow">
 <FontAwesomeIcon icon="search" />{" "}          
 </button>
        </div>

        <div className="col-4 col-md-4 offset-md-4">
          <div className="display-inline-flex">
            <input
              className="search"
              type="checkbox"
              name="searchBV"
              onChange={this.handleSearch}
              value={this.state.searchBV}
            />
            <p>Nur nach BV suchen</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SearchDeliveryNotesBills;
